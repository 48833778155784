export function animation() {
  let fadeInImageTarget = document.querySelectorAll('.fade-in-image');
  window.addEventListener('scroll', () => {
    for (let i = 0; i < fadeInImageTarget.length; i++) {
      const rect = fadeInImageTarget[i].getBoundingClientRect().top;
      const scroll = window.pageYOffset || document.documentElement.scrollTop;
      const offset = rect + scroll;
      const windowHeight = window.innerHeight;
      if (scroll > offset - windowHeight + (windowHeight / 2)) {
        fadeInImageTarget[i].classList.add('scroll-in');
      }
    }
  });


  let fadeInTarget = document.querySelectorAll('.fade-in');
  window.addEventListener('scroll', () => {
    for (let i = 0; i < fadeInTarget.length; i++) {
      const rect = fadeInTarget[i].getBoundingClientRect().top;
      const scroll = window.pageYOffset || document.documentElement.scrollTop;
      const offset = rect + scroll;
      const windowHeight = window.innerHeight;
      if (scroll > offset - windowHeight + (windowHeight / 3)) {
        fadeInTarget[i].classList.add('scroll-in');
      }
    }
  });
  window.addEventListener('load', () => {
    let fadeInHeaderImageTarget = document.querySelectorAll('.fade-in-headerimage');
    if (fadeInHeaderImageTarget.length > 0) {
      fadeInHeaderImageTarget[0].classList.add('scroll-in');
    }
  });
}
