// const moment = require('moment');
// import { Navigation, Pagination, Autoplay, EffectFade, Swiper } from 'swiper'
// Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger);

export function index() {
  $(window).on('load', function () {
    setTimeout(() => {
      $('.toppage__loader').addClass('is_close');
    }, 500);
  })
  if ($('.toppage').length) {

    ScrollTrigger.create({
      trigger: ".toppage__mainimage",
      start: "top top",
      endTrigger: ".footer",
      end: "bottom+=200px bottom",
      // markers: true,
      //invalidateOnRefresh: true,
      toggleClass: { targets: ".header h1", className: "is_active" },
      once: false,
    });

  }
}
