import { index } from "./components/index";
import { animation } from "./components/animation";

index();
animation();


$(function () {
  $(document).on('click', '.navopen', function () {
    $('.nav').toggleClass('is_active');
    $('.navopen').toggleClass('is_active');
  })


  $.ajax({
    type: 'GET',
    url: '/instagram.php',
    dataType: 'json',
    success: function (json) {
      const datas = json.media.data;
      console.log(datas);
      for (let i in datas) {
        let thumbnail = datas[i].thumbnail_url;
        let url;
        if (typeof thumbnail !== "undefined") {
          url = datas[i].thumbnail_url;
        } else {
          url = datas[i].media_url;
        }
        let shortcode = datas[i].permalink;
        this.html = `<li class="item"><a href="${shortcode}" target="_blank" style="background:url(${url})"></a></li>`;
        $(".workspage__list").append(this.html);
      }
    }
  });
})
